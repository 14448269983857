/*Theme Dark Blue Styles*/
$app-primary-healthylab: #2cc0a7 !default;
$secondary-healthylab: #ff6e40;

$sidebar-healthylab: #252525 !default;
$sidebar-text-healthylab: #a1a1a1 !default;
$sidebar-darken-blue: darken($sidebar-healthylab, 3%);
$sidebar-bg-darken-highlight-blue: $app-primary-healthylab;

// Links
$link-hover-healthylab: $white;

//-Base-scss
.healthylab .right-arrow {
    color: $app-primary-healthylab;
    &:after {
        color: $app-primary-healthylab;
    }
}

//- Bootstrap file Style
.healthylab {
    a,
    .card-link,
    .jr-link,
    .jr-link.text-primary {
        color: $app-primary-healthylab;
        text-align: right;
        &:focus,
        &:hover {
            color: darken($app-primary-healthylab, 10%);
        }
    }

    & .text-primary {
        color: $app-primary-healthylab !important;
    }

    & .page-link {
        color: $app-primary-healthylab;
        &:focus,
        &:hover {
            color: $app-primary-healthylab;
        }
    }

    & .page-heading .breadcrumb-item.active {
        color: $app-primary-healthylab;
    }

    & .bg-primary,
    & .badge-primary {
        background-color: $app-primary-healthylab !important;
        color: $link-hover-healthylab !important;
    }

    & .btn-primary {
        background-color: $app-primary-healthylab;
        border-color: $app-primary-healthylab;
        color: $link-hover-healthylab;
        &:hover,
        &:focus,
        &:active {
            background-color: darken($app-primary-healthylab, 5%) !important;
            border-color: darken($app-primary-healthylab, 5%) !important;
            color: $link-hover-healthylab !important;
        }
    }

    & .bg-primary {
        &.lighten-4 {
            background-color: #bbdefb !important;
        }

        &.lighten-3 {
            background-color: #90caf9 !important;
        }

        &.lighten-2 {
            background-color: #64b5f6 !important;
        }

        &.lighten-1 {
            background-color: #42a5f5 !important;
        }

        &.darken-1 {
            background-color: #1e88e5 !important;
        }

        &.darken-2 {
            background-color: #1976d2 !important;
        }

        &.darken-3 {
            background-color: #1565c0 !important;
        }

        &.darken-4 {
            background-color: #0d47a1 !important;
        }

        &.accent-1 {
            background-color: #82b1ff !important;
        }

        &.accent-2 {
            background-color: #448aff !important;
        }

        &.accent-3 {
            background-color: #2979ff !important;
        }

        &.accent-4 {
            background-color: #2962ff !important;
        }
    }
}

//Secondary
.healthylab {
    .jr-link.text-secondary {
        color: $secondary-healthylab;
        &:focus,
        &:hover {
            color: darken($secondary-healthylab, 10%) !important;
        }
    }

    & .text-secondary {
        color: $secondary-healthylab !important;
    }

    & .bg-secondary,
    & .badge-secondary {
        background-color: $secondary-healthylab !important;
        color: $link-hover-healthylab !important;
    }

    & .btn-secondary {
        background-color: $secondary-healthylab;
        border-color: $secondary-healthylab;
        color: $link-hover-healthylab;

        &:hover,
        &:focus,
        &:active {
            background-color: darken($secondary-healthylab, 5%) !important;
            border-color: darken($secondary-healthylab, 5%) !important;
            color: $link-hover-healthylab !important;
        }
    }
}

//_header.scss
.healthylab .app-main-header {
    background-color: $app-primary-healthylab !important;

    & .search-dropdown input {
        @media screen and (max-width: 991px) {
            background-color: $white;
        }
    }
}

//_right-sidebar.scss
.healthylab .color-theme-header {
    background-color: $app-primary-healthylab;
}

//_sidebar.scss
.healthylab .side-nav {
    background-color: $sidebar-healthylab !important;
    color: $sidebar-text-healthylab !important;
    @include box-shadow(none);

    & .user-profile {
        background-color: $sidebar-darken-blue;
        @include box-shadow(none);
        position: relative;
        z-index: 2;
    }

    & .customizer {
        border-bottom: 0 none;
    }

    & .user-detail {
        & .user-name {
            color: $link-hover-healthylab;
        }
    }
}

.healthylab .nav-section {
    & .nav-header {
        color: $sidebar-text-healthylab;
    }
}

.healthylab .nav-collapse {
    & .nav-collapse-btn {
        color: $sidebar-text-healthylab;

        &:focus,
        &:hover {
            background-color: $sidebar-darken-blue;
            color: $link-hover-healthylab;
        }
    }

    &.open {
        background-color: $sidebar-darken-blue;

        & .nav-collapse-btn {
            background-color: $sidebar-darken-blue;
            color: $link-hover-healthylab;

            &:focus,
            &:hover {
                background-color: $sidebar-darken-blue;
                color: $link-hover-healthylab;
            }
        }
    }
}

.healthylab .nav-collapse {
    & .nav-menu-item .nav-menu-link {
        color: $sidebar-text-healthylab;

        &:focus,
        &:hover,
        &.active {
            background-color: $sidebar-darken-blue;
            color: $link-hover-healthylab;
        }

        &:after {
            background-color: $white;
        }
    }

    &.open {
        & .nav-menu-item .nav-menu-link.active {
            background-color: $sidebar-bg-darken-highlight-blue;
            color: $link-hover-healthylab;
        }
    }
}

.healthylab .nav-menu-item {
    & .nav-menu-link {
        &:focus,
        &:hover,
        &.active {
            background-color: $sidebar-darken-blue;
            color: $link-hover-healthylab;
        }
    }
}

/*Header top Navbar Styles*/
.healthylab .navbar-nav {
    & li {
        &:hover > a,
        &:focus > a,
        &:hover > .nav-link,
        &:focus > .nav-link {
            color: $app-primary-healthylab;
        }

        & a,
        & .nav-link {
            color: $sidebar-text-color;
        }

        & a:hover,
        & a:focus,
        & .nav-link:hover,
        & .nav-link:focus {
            color: $app-primary-healthylab;
        }
    }

    & li.nav-item > a,
    & li.nav-item > .nav-link {
        color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
        color: $secondary-healthylab;
    }

    ul.sub-menu {
        & li a.active,
        & li.active > a,
        & li .nav-link.active,
        & li.active > .nav-link {
            color: $app-primary-healthylab;
        }
    }

    & .nav-arrow {
        & > a:before,
        & > .nav-link:before {
            color: $sidebar-text-color;
        }

        & a:hover,
        & a:focus,
        & .nav-link:hover,
        & .nav-link:focus {
            &:before {
                color: $app-primary-healthylab;
            }
        }

        &:hover > a:before,
        &:focus > a:before,
        &.active > a:before,
        &:hover > .nav-link:before,
        &:focus > .nav-link:before,
        &.active > .nav-link:before {
            color: $app-primary-healthylab;
        }
    }
}

.healthylab .app-top-nav {
    & .navbar-nav {
        & li.nav-item > a,
        & li.nav-item > .nav-link {
            color: $white;
        }

        & li.nav-item:hover > a,
        & li.nav-item:focus > a,
        & li.nav-item > a:hover,
        & li.nav-item > a:focus,
        & li.nav-item.active > a,
        & li.nav-item:hover > .nav-link,
        & li.nav-item:focus > .nav-link,
        & li.nav-item > .nav-link:hover,
        & li.nav-item > .nav-link:focus,
        & li.nav-item.active > .nav-link {
            color: $secondary-healthylab;
        }
    }
}

.healthylab .app-header-horizontal {
    & .app-main-header-top {
        @include box-shadow(none !important);
    }
}

.healthylab .header-notifications .app-notification {
    & .jr-list-link {
        @include hover-focus-active {
            color: $app-primary-healthylab;
            border-color: $app-primary-healthylab;
        }
    }
}

//_app-module.scss
.healthylab .module-nav {
    & li {
        & a.active {
            border-color: $app-primary-healthylab;
        }

        & .jr-link.active {
            color: $app-primary-healthylab;
        }
    }
}

//_calendar.scss
.healthylab .rbc-event {
    background-color: $app-primary-healthylab;
}

.healthylab .rbc-event.rbc-selected {
    background-color: darken($app-primary-healthylab, 10%);
}

.healthylab .rbc-slot-selection {
    background-color: rgba($app-primary-healthylab, 0.7);
}

.healthylab .rbc-toolbar button:active,
.healthylab .rbc-toolbar button.rbc-active {
    background-color: rgba($app-primary-healthylab, 0.9);
    border-color: $app-primary-healthylab;
}

.healthylab .rbc-toolbar button:active:hover,
.healthylab .rbc-toolbar button.rbc-active:hover,
.healthylab .rbc-toolbar button:active:focus,
.healthylab .rbc-toolbar button.rbc-active:focus {
    background-color: rgba($app-primary-healthylab, 0.9);
    border-color: $app-primary-healthylab;
}

.healthylab .rbc-toolbar button:focus {
    background-color: rgba($app-primary-healthylab, 0.9);
    border-color: $app-primary-healthylab;
}

.healthylab .rbc-toolbar button:hover {
    background-color: rgba($app-primary-healthylab, 0.9);
    border-color: $app-primary-healthylab;
}

//_chat.scss
.healthylab .chat-sidenav-title {
    color: $app-primary-healthylab;
}

.healthylab .chat-user-item {
    &.active,
    &:hover {
        background-color: lighten($app-primary-healthylab, 45%);
    }
}

//_dashboard.scss
.healthylab .contact-list {
    & i {
        color: $app-primary-healthylab;
    }
}

//The link which when clicked opens the collapsable ChatUserList
.healthylab .Collapsible__trigger {
    background: $app-primary-healthylab;
}

.healthylab .categories-list {
    & li a:focus,
    & li a:hover,
    & li.active a {
        color: $app-primary-healthylab;
    }
}

//_login.scss

.healthylab .login-content .form-control {
    &:focus {
        border-color: $app-primary-healthylab;
    }
}

//_portfolio.scss
.healthylab .filter-with-bg-color ul li {
    .jr-link {
        border-color: $app-primary-healthylab;

        &:hover,
        &:focus,
        &.active {
            background-color: $app-primary-healthylab;
        }
    }
}

//_card.scss
.healthylab .profile-intro {
    & .icon {
        color: $app-primary-healthylab;
    }
}

.healthylab .social-link {
    & li.active a,
    & li a:hover,
    & li a:focus {
        color: $secondary-healthylab;
    }
}

//_tables.scss
.healthylab .actions {
    color: $secondary-healthylab;
}

.healthylab .table-hover tbody tr:hover {
    background-color: rgba($app-primary-healthylab, 0.075);
}

//Border Color

.healthylab .border-primary {
    border-color: $app-primary-healthylab !important;
}

// login page content

.healthylab .app-logo-content {
    background-color: $app-primary-healthylab;
}

.healthylab .app-social-block {
    & .social-link button {
        border: solid 1px $app-primary-healthylab;
        color: $app-primary-healthylab;

        &:hover,
        &:focus {
            color: $white;
            background-color: $app-primary-healthylab;
        }
    }
}

/*Button Group Styles*/
.healthylab .btn-group,
.healthylab .btn-group-vertical {
    > .jr-btn {
        &.active {
            background-color: $app-primary-healthylab;
            border-color: $app-primary-healthylab;
        }
    }

    > .jr-flat-btn {
        background-color: transparent;
        border-color: transparent;
        &.active {
            background-color: transparent;
            color: $app-primary-healthylab;
            &:hover,
            &:focus {
                background-color: rgba($app-primary-healthylab, 0.12);
                color: $app-primary-healthylab;
            }
        }
    }
}

/*Classic Dashboard Styles*/
.healthylab .jr-fillchart-btn-close,
.healthylab .jr-onchart .jr-badge-up,
.healthylab .jr-task-list-item:hover .jr-text-hover {
    color: $app-primary-healthylab;
}

.healthylab .jr-entry-title:before {
    background-color: $app-primary-healthylab;
}

.healthylab .jr-card-ticketlist {
    & .jr-task-list-item:hover .jr-task-item-title,
    .jr-link {
        color: $app-primary-healthylab;
    }
}

.healthylab .slick-dots li.slick-active button:before {
    border-color: $app-primary-healthylab;
}

//Nav Styles
.healthylab .nav-pills .nav-link.active,
.healthylab .nav-pills .show > .nav-link {
    color: $white !important;
    background-color: $app-primary-healthylab;
}

// Gradient Color Class
.healthylab .bg-gradient-primary {
    @include gradient-directional($app-primary-healthylab, lighten($app-primary-healthylab, 16%), 0deg);
}

.healthylab .bg-gradient-primary-x {
    @include gradient-x(darken($app-primary-healthylab, 10%), lighten($app-primary-healthylab, 16%), 70%, 100%);
}

//Profile style
.healthylab .jr-profile-banner {
    background-color: $app-primary-healthylab;
    color: $white;

    & .jr-link {
        color: $white;

        &:hover,
        &:focus {
            color: $secondary-healthylab;
        }
    }
}

.healthylab .dropdown-item {
    &.active,
    &:active {
        background-color: $app-primary-healthylab;
        color: $white;
    }

    &[class*="text-"] {
        &.active,
        &:active {
            background-color: transparent;
        }
    }
}

.healthylab .app-logo-content {
    text-align: center;
}
.healthylab .app-logo-content .logo-title {
    text-transform: initial;
    font-family: sans-serif;
}

.healthylab .app-sidebar-logo .logo-title {
    font-size: 28px;
    text-transform: initial;
    padding-left: 6px;
    padding-top: 6px;
    font-family: sans-serif;
}

/*React Joyride Styles*/

$joyride-color: #f04 !default;
$joyride-zindex: 1500 !default;
$joyride-overlay-color: rgba(#000, 0.5) !default;

$joyride-beacon-color: $joyride-color !default;
$joyride-beacon-size: 36px !default;

$joyride-hole-border-radius: 4px !default;
$joyride-hole-shadow: 0 0 15px rgba(#000, 0.5) !default;

$joyride-tooltip-animation: joyride-tooltip 0.4s forwards;
$joyride-tooltip-animation-timing: cubic-bezier(0, 1.05, 0.55, 1.18);

$joyride-tooltip-arrow-size: 36px !default;
$joyride-tooltip-bg-color: #fff !default;
$joyride-tooltip-border-radius: 4px !default;
$joyride-tooltip-color: #555 !default;
$joyride-tooltip-font-size: 14px !default;
$joyride-tooltip-padding: 20px !default;
$joyride-tooltip-shadow: (
    x: 1px,
    y: 2px,
    blur: 3px,
    color: rgba(#000, 0.3),
) !default;
$joyride-tooltip-width: (290px, 360px, 450px) !default;

$joyride-close: (
    color: rgba($joyride-tooltip-color, 0.5),
    size: 12px,
    top: 10px,
    right: 10px,
) !default;
$joyride-close-visible: true !default;
$joyride-close-image: "data:image/svg+xml,%3Csvg%20width%3D%22#{map-get($joyride-close, "size")}%22%20height%3D%22#{map-get($joyride-close, "size")}%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.117.323l-6.073%206.075-5.449-6.075c-.431-.431-1.131-.431-1.562%200-.431.431-.431%201.131%200%201.562l5.449%206.075-6.159%206.159c-.431.431-.431%201.134%200%201.562.431.431%201.131.431%201.562%200l6.075-6.073%205.449%206.073c.429.431%201.131.431%201.56%200%20.431-.429.431-1.131%200-1.562l-5.452-6.073%206.159-6.161c.431-.431.431-1.131%200-1.562-.431-.431-1.129-.431-1.56%200z%22%20fill%3D%22#{map-get($joyride-close, "color")}%22%2F%3E%3C%2Fsvg%3E";

$joyride-header-color: $joyride-tooltip-color !default;
$joyride-header-font-size: 16px !default;
$joyride-header-border-color: $joyride-color !default;
$joyride-header-border-width: 1px !default;

$joyride-button-bg-color: $joyride-color !default;
$joyride-button-color: #fff !default;
$joyride-button-border-radius: 4px !default;

$joyride-back-button-color: $joyride-color !default;
$joyride-skip-button-color: #ccc !default;

$joyride-tooltip-arrow-height: $joyride-tooltip-arrow-size / 2;
$joyride-tooltip-arrow-scale: ($joyride-tooltip-arrow-size / ($joyride-tooltip-arrow-size * 0 + 1)) / 16; // strip-units crazy hack

@function joyride-arrow($location: top, $bg-color: $joyride-tooltip-bg-color) {
    $height: $joyride-tooltip-arrow-height;
    $width: $joyride-tooltip-arrow-size;
    $rotate: "0";
    $arrow-color: $white;

    @if $location == bottom {
        $rotate: "180 8 4";
    } @else if $location == left {
        $height: $joyride-tooltip-arrow-size;
        $width: $joyride-tooltip-arrow-height;
        $rotate: "270 8 8";
    } @else if $location == right {
        $height: $joyride-tooltip-arrow-size;
        $width: $joyride-tooltip-arrow-height;
        $rotate: "90 4 4";
    }

    @return "data:image/svg+xml,%3Csvg%20width%3D%22#{$width}%22%20height%3D%22#{$height}%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22#{$arrow-color}%22%20transform%3D%22scale%28#{$joyride-tooltip-arrow-scale}%29%20rotate%28#{$rotate}%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E";
}

@mixin reset-button() {
    //appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    padding: 0;

    &:active,
    &:focus,
    &:hover {
        outline: none;
    }
}

.joyride {
    &-beacon {
        @include reset-button();
        height: $joyride-beacon-size;
        position: relative;
        width: $joyride-beacon-size;
        z-index: $joyride-zindex;

        &__inner {
            animation: joyride-beacon-inner 1.2s infinite ease-in-out;
            background-color: $joyride-beacon-color;
            border-radius: 50%;
            display: block;
            height: 50%;
            left: 50%;
            opacity: 0.7;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 50%;
        }

        &__outer {
            animation: joyride-beacon-outer 1.2s infinite ease-in-out;
            background-color: rgba($joyride-beacon-color, 0.2);
            border: ($joyride-beacon-size / 18) solid $joyride-beacon-color;
            border-radius: 50%;
            box-sizing: border-box;
            display: block;
            height: 100%;
            left: 0;
            opacity: 0.9;
            position: absolute;
            top: 0;
            transform: translateY(-50%);
            transform-origin: center;
            width: 100%;
        }
    }

    &-overlay {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: $joyride-zindex;
    }

    &-hole {
        border-radius: $joyride-hole-border-radius;
        box-shadow:
            0 0 0 9999px $joyride-overlay-color,
            $joyride-hole-shadow;
        position: absolute;

        &.safari {
            box-shadow:
                0 0 999px 9999px $joyride-overlay-color,
                $joyride-hole-shadow;
        }
    }

    &-tooltip {
        background-color: $joyride-tooltip-bg-color;
        border-radius: $joyride-tooltip-border-radius;
        color: $joyride-tooltip-color;
        cursor: default;
        filter: drop-shadow(
                #{-(map-get($joyride-tooltip-shadow, "x"))} #{-(map-get($joyride-tooltip-shadow, "y"))} #{map-get(
                        $joyride-tooltip-shadow,
                        "blur"
                    )} #{map-get($joyride-tooltip-shadow, "color")}
            )
            drop-shadow(
                #{map-get($joyride-tooltip-shadow, "x")} #{map-get($joyride-tooltip-shadow, "y")} #{map-get(
                        $joyride-tooltip-shadow,
                        "blur"
                    )} #{map-get($joyride-tooltip-shadow, "color")}
            );
        opacity: 0;
        padding: $joyride-tooltip-padding;
        //pointer-events: auto;
        transform: translate3d(0, 0, 0);
        width: nth($joyride-tooltip-width, 1);
        z-index: $joyride-zindex + 10;

        &--animate {
            animation: $joyride-tooltip-animation;
            animation-timing-function: $joyride-tooltip-animation-timing;
        }

        &__triangle {
            background-repeat: no-repeat;
            // overflow: hidden;
            position: absolute;
        }

        &.bottom,
        &.bottom-left,
        &.bottom-right {
            margin-top: $joyride-tooltip-arrow-height;

            .joyride-tooltip__triangle {
                background-image: url("#{joyride-arrow(bottom)}");
                height: $joyride-tooltip-arrow-height;
                left: 50%;
                top: -($joyride-tooltip-arrow-height - 2);
                transform: translateX(-50%);
                width: $joyride-tooltip-arrow-size;
            }
        }

        &.top,
        &.top-left,
        &.top-right {
            margin-bottom: $joyride-tooltip-arrow-height;

            .joyride-tooltip__triangle {
                background-image: url("#{joyride-arrow(top)}");
                bottom: -($joyride-tooltip-arrow-height - 2);
                height: $joyride-tooltip-arrow-height;
                left: 50%;
                transform: translateX(-50%);
                width: $joyride-tooltip-arrow-size;
            }
        }

        &.bottom-left,
        &.top-left {
            .joyride-tooltip__triangle {
                left: 3%;
                transform: translateX(0);

                @media screen and (min-width: 480px) {
                    left: 2%;
                }
            }
        }

        &.bottom-right,
        &.top-right {
            .joyride-tooltip__triangle {
                left: auto;
                right: 3%;
                transform: translateX(0);

                @media screen and (min-width: 480px) {
                    right: 2%;
                }
            }
        }

        &.left {
            margin-right: $joyride-tooltip-arrow-height;

            .joyride-tooltip__triangle {
                background-image: url("#{joyride-arrow(left)}");
                height: $joyride-tooltip-arrow-size;
                right: -($joyride-tooltip-arrow-height - 2);
                width: $joyride-tooltip-arrow-height;
            }
        }

        &.right {
            margin-left: $joyride-tooltip-arrow-height;

            .joyride-tooltip__triangle {
                background-image: url("#{joyride-arrow(right)}");
                height: $joyride-tooltip-arrow-size;
                left: -($joyride-tooltip-arrow-height - 2);
                width: $joyride-tooltip-arrow-height;
            }
        }

        &__close {
            @include reset-button();
            background-image: url($joyride-close-image);
            background-repeat: no-repeat;
            background-size: contain;
            height: map-get($joyride-close, "size");
            position: absolute;
            right: map-get($joyride-close, "right");
            text-decoration: none;
            top: map-get($joyride-close, "top");
            width: map-get($joyride-close, "size");
            z-index: 10;

            &:hover,
            &:focus {
                color: darken(map-get($joyride-close, "color"), 10);
                outline: none;
            }

            &--header {
                right: $joyride-tooltip-padding;
                top: $joyride-tooltip-padding;
            }

            @if $joyride-close-visible {
                display: block;
            } @else {
                display: none;
            }
        }

        &__header {
            border-bottom: $joyride-header-border-width solid $joyride-header-border-color;
            color: $joyride-header-color;
            font-size: $joyride-header-font-size;
            padding-bottom: 6px;
            padding-right: 18px;
            position: relative;

            ~ .joyride-tooltip__main {
                padding: 12px 0 18px;
            }
        }

        &__main {
            font-size: $joyride-tooltip-font-size;
            padding-bottom: 18px;
            padding-right: 18px;
        }

        &__footer {
            text-align: right;
        }

        &__button {
            @include reset-button();

            &--primary {
                background-color: $joyride-button-bg-color;
                border-radius: $joyride-button-border-radius;
                color: $joyride-button-color;
                padding: 6px 12px;
                transition: background-color 0.2s ease-in-out;

                &:active,
                &:focus,
                &:hover {
                    background-color: lighten($joyride-button-bg-color, 6);
                    color: $joyride-button-color;
                }
            }

            &--secondary {
                color: $joyride-back-button-color;
                margin-right: 10px;
            }

            &--skip {
                color: $joyride-skip-button-color;
                float: left;
                margin-right: 10px;
            }
        }

        &--standalone {
            .joyride-tooltip__main {
                padding-bottom: 0;
            }

            .joyride-tooltip__footer {
                display: none;
            }
        }

        @media screen and (min-width: 480px) {
            width: nth($joyride-tooltip-width, 2);
        }

        @media screen and (min-width: 960px) {
            width: nth($joyride-tooltip-width, 3);
        }
    }
}

@keyframes joyride-tooltip {
    0% {
        transform: scale(0.1);
    }

    100% {
        opacity: 1;
        transform: perspective(1px) scale(1);
    }
}

@keyframes joyride-beacon-inner {
    20% {
        opacity: 0.9;
    }

    90% {
        opacity: 0.7;
    }
}

@keyframes joyride-beacon-outer {
    0% {
        transform: scale(1);
    }

    45% {
        opacity: 0.7;
        transform: scale(0.75);
    }

    100% {
        opacity: 0.9;
        transform: scale(1);
    }
}

/*Dropzone Styles*/
.dropzone {
    &-card {
        position: relative;
    }

    margin-bottom: 20px;

    &-file-btn {
        width: 200px;
        height: 200px;
        border: 2px dashed $gray-600;
        margin: 0 auto;
        padding: 15px;
        @include display-flex(flex, column, nowrap);
        @include justify-content(center);
        @include border-radius(5px);
        cursor: pointer;

        & :last-child {
            margin-bottom: 0;
        }
    }

    & .btn {
        margin-top: 20px;
    }
}

.upload-file-list {
    list-style: none;
    padding-left: 0;

    & li {
        margin-bottom: 5px;
    }
}

.form-group-dropzone {
    & label {
        margin-bottom: 6px;
    }

    & .form-control {
        max-width: 200px;
        margin: 0 auto;
    }
}

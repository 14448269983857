/*Theme Dark Blue Styles*/
$app-primary-zakaut: #bfa08a !default;
$secondary-zakaut: #ff6e40;

$sidebar-zakaut: #252525 !default;
$sidebar-text-zakaut: #a1a1a1 !default;
$sidebar-darken-blue: darken($sidebar-zakaut, 3%);
$sidebar-bg-darken-highlight-blue: $app-primary-zakaut;
$app-link-zakaut: #18578a; /*#2d3849;*/
$app-light-zakaut: #fff0e6;

// Links
$link-hover-zakaut: $white;

//-Base-scss
.zakaut .right-arrow {
    color: $app-primary-zakaut;
    &:after {
        color: $app-primary-zakaut;
    }
}

//- Bootstrap file Style
.zakaut {
    a,
    .card-link,
    .jr-link,
    .jr-link.text-primary {
        color: $app-link-zakaut;
        &:focus,
        &:hover {
            color: darken($app-primary-zakaut, 10%);
        }
    }

    & .text-primary {
        color: $app-primary-zakaut !important;
    }

    & .page-link {
        color: $app-primary-zakaut;
        &:focus,
        &:hover {
            color: $app-primary-zakaut;
        }
    }

    & .page-heading .breadcrumb-item.active {
        color: $app-primary-zakaut;
    }

    & .bg-primary,
    & .badge-primary {
        background-color: $app-primary-zakaut !important;
        color: $link-hover-zakaut !important;
    }

    & .btn-primary {
        background-color: $app-primary-zakaut;
        border-color: $app-primary-zakaut;
        color: $link-hover-zakaut;
        &:hover,
        &:focus,
        &:active {
            background-color: darken($app-primary-zakaut, 5%) !important;
            border-color: darken($app-primary-zakaut, 5%) !important;
            color: $link-hover-zakaut !important;
        }
    }

    & .bg-primary {
        &.lighten-4 {
            background-color: #bbdefb !important;
        }

        &.lighten-3 {
            background-color: #90caf9 !important;
        }

        &.lighten-2 {
            background-color: #64b5f6 !important;
        }

        &.lighten-1 {
            background-color: #42a5f5 !important;
        }

        &.darken-1 {
            background-color: #1e88e5 !important;
        }

        &.darken-2 {
            background-color: #1976d2 !important;
        }

        &.darken-3 {
            background-color: #1565c0 !important;
        }

        &.darken-4 {
            background-color: #0d47a1 !important;
        }

        &.accent-1 {
            background-color: #82b1ff !important;
        }

        &.accent-2 {
            background-color: #448aff !important;
        }

        &.accent-3 {
            background-color: #2979ff !important;
        }

        &.accent-4 {
            background-color: #2962ff !important;
        }
    }
}

//Secondary
.zakaut {
    .jr-link.text-secondary {
        color: $secondary-zakaut;
        &:focus,
        &:hover {
            color: darken($secondary-zakaut, 10%) !important;
        }
    }

    & .text-secondary {
        color: $secondary-zakaut !important;
    }

    & .bg-secondary,
    & .badge-secondary {
        background-color: $secondary-zakaut !important;
        color: $link-hover-zakaut !important;
    }

    & .btn-secondary {
        background-color: $secondary-zakaut;
        border-color: $secondary-zakaut;
        color: $link-hover-zakaut;

        &:hover,
        &:focus,
        &:active {
            background-color: darken($secondary-zakaut, 5%) !important;
            border-color: darken($secondary-zakaut, 5%) !important;
            color: $link-hover-zakaut !important;
        }
    }
}

//_header.scss
.zakaut .app-main-header {
    background-color: $app-primary-zakaut !important;

    & .search-dropdown input {
        @media screen and (max-width: 991px) {
            background-color: $white;
        }
    }
}

//_right-sidebar.scss
.zakaut .color-theme-header {
    background-color: $app-primary-zakaut;
}

//_sidebar.scss
.zakaut .side-nav {
    background-color: $sidebar-zakaut !important;
    color: $sidebar-text-zakaut !important;
    @include box-shadow(none);

    & .user-profile {
        background-color: $sidebar-darken-blue;
        @include box-shadow(none);
        position: relative;
        z-index: 2;
    }

    & .customizer {
        border-bottom: 0 none;
    }

    & .user-detail {
        & .user-name {
            color: $link-hover-zakaut;
        }
    }
}

.zakaut .nav-section {
    & .nav-header {
        color: $sidebar-text-zakaut;
    }
}

.zakaut .nav-collapse {
    & .nav-collapse-btn {
        color: $sidebar-text-zakaut;

        &:focus,
        &:hover {
            background-color: $sidebar-darken-blue;
            color: $link-hover-zakaut;
        }
    }

    &.open {
        background-color: $sidebar-darken-blue;

        & .nav-collapse-btn {
            background-color: $sidebar-darken-blue;
            color: $link-hover-zakaut;

            &:focus,
            &:hover {
                background-color: $sidebar-darken-blue;
                color: $link-hover-zakaut;
            }
        }
    }
}

.zakaut .nav-collapse {
    & .nav-menu-item .nav-menu-link {
        color: $sidebar-text-zakaut;

        &:focus,
        &:hover,
        &.active {
            background-color: $sidebar-darken-blue;
            color: $link-hover-zakaut;
        }

        &:after {
            background-color: $white;
        }
    }

    &.open {
        & .nav-menu-item .nav-menu-link.active {
            background-color: $sidebar-bg-darken-highlight-blue;
            color: $link-hover-zakaut;
        }
    }
}

.zakaut .nav-menu-item {
    & .nav-menu-link {
        &:focus,
        &:hover,
        &.active {
            background-color: $sidebar-darken-blue;
            color: $link-hover-zakaut;
        }
    }
}

/*Header top Navbar Styles*/
.zakaut .navbar-nav {
    & li {
        &:hover > a,
        &:focus > a,
        &:hover > .nav-link,
        &:focus > .nav-link {
            color: $app-primary-zakaut;
        }

        & a,
        & .nav-link {
            color: $sidebar-text-color;
        }

        & a:hover,
        & a:focus,
        & .nav-link:hover,
        & .nav-link:focus {
            color: $app-primary-zakaut;
        }
    }

    & li.nav-item > a,
    & li.nav-item > .nav-link {
        color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
        color: $secondary-zakaut;
    }

    ul.sub-menu {
        & li a.active,
        & li.active > a,
        & li .nav-link.active,
        & li.active > .nav-link {
            color: $app-primary-zakaut;
        }
    }

    & .nav-arrow {
        & > a:before,
        & > .nav-link:before {
            color: $sidebar-text-color;
        }

        & a:hover,
        & a:focus,
        & .nav-link:hover,
        & .nav-link:focus {
            &:before {
                color: $app-primary-zakaut;
            }
        }

        &:hover > a:before,
        &:focus > a:before,
        &.active > a:before,
        &:hover > .nav-link:before,
        &:focus > .nav-link:before,
        &.active > .nav-link:before {
            color: $app-primary-zakaut;
        }
    }
}

.zakaut .app-top-nav {
    & .navbar-nav {
        & li.nav-item > a,
        & li.nav-item > .nav-link {
            color: $white;
        }

        & li.nav-item:hover > a,
        & li.nav-item:focus > a,
        & li.nav-item > a:hover,
        & li.nav-item > a:focus,
        & li.nav-item.active > a,
        & li.nav-item:hover > .nav-link,
        & li.nav-item:focus > .nav-link,
        & li.nav-item > .nav-link:hover,
        & li.nav-item > .nav-link:focus,
        & li.nav-item.active > .nav-link {
            color: $secondary-zakaut;
        }
    }
}

.zakaut .app-header-horizontal {
    & .app-main-header-top {
        @include box-shadow(none !important);
    }
}

.zakaut .header-notifications .app-notification {
    & .jr-list-link {
        @include hover-focus-active {
            color: $app-primary-zakaut;
            border-color: $app-primary-zakaut;
        }
    }
}

//_app-module.scss
.zakaut .module-nav {
    & li {
        & a.active {
            border-color: $app-primary-zakaut;
        }

        & .jr-link.active {
            color: $app-primary-zakaut;
        }
    }
}

//_calendar.scss
.zakaut .rbc-event {
    background-color: $app-primary-zakaut;
}

.zakaut .rbc-event.rbc-selected {
    background-color: darken($app-primary-zakaut, 10%);
}

.zakaut .rbc-slot-selection {
    background-color: rgba($app-primary-zakaut, 0.7);
}

.zakaut .rbc-toolbar button:active,
.zakaut .rbc-toolbar button.rbc-active {
    background-color: rgba($app-primary-zakaut, 0.9);
    border-color: $app-primary-zakaut;
}

.zakaut .rbc-toolbar button:active:hover,
.zakaut .rbc-toolbar button.rbc-active:hover,
.zakaut .rbc-toolbar button:active:focus,
.zakaut .rbc-toolbar button.rbc-active:focus {
    background-color: rgba($app-primary-zakaut, 0.9);
    border-color: $app-primary-zakaut;
}

.zakaut .rbc-toolbar button:focus {
    background-color: rgba($app-primary-zakaut, 0.9);
    border-color: $app-primary-zakaut;
}

.zakaut .rbc-toolbar button:hover {
    background-color: rgba($app-primary-zakaut, 0.9);
    border-color: $app-primary-zakaut;
}

//_chat.scss
.zakaut .chat-sidenav-title {
    color: $app-primary-zakaut;
}

.zakaut .chat-user-item {
    &.active,
    &:hover {
        background-color: $app-light-zakaut;
    }
}

//_dashboard.scss
.zakaut .contact-list {
    & i {
        color: $app-primary-zakaut;
    }
}

//The link which when clicked opens the collapsable ChatUserList
.zakaut .Collapsible__trigger {
    background: $app-primary-zakaut;
}

.zakaut .categories-list {
    & li a:focus,
    & li a:hover,
    & li.active a {
        color: $app-primary-zakaut;
    }
}

//_login.scss

.zakaut .login-content .form-control {
    &:focus {
        border-color: $app-primary-zakaut;
    }
}

//_portfolio.scss
.zakaut .filter-with-bg-color ul li {
    .jr-link {
        border-color: $app-primary-zakaut;

        &:hover,
        &:focus,
        &.active {
            background-color: $app-primary-zakaut;
        }
    }
}

//_card.scss
.zakaut .profile-intro {
    & .icon {
        color: $app-primary-zakaut;
    }
}

.zakaut .social-link {
    & li.active a,
    & li a:hover,
    & li a:focus {
        color: $secondary-zakaut;
    }
}

//_tables.scss
.zakaut .actions {
    color: $secondary-zakaut;
}

.zakaut .table-hover tbody tr:hover {
    background-color: rgba($app-primary-zakaut, 0.075);
}

//Border Color

.zakaut .border-primary {
    border-color: $app-primary-zakaut !important;
}

// login page content

.zakaut .app-logo-content {
    background-color: #fafafa;
}

.zakaut .app-social-block {
    & .social-link button {
        border: solid 1px $app-primary-zakaut;
        color: $app-primary-zakaut;

        &:hover,
        &:focus {
            color: $white;
            background-color: $app-primary-zakaut;
        }
    }
}

/*Button Group Styles*/
.zakaut .btn-group,
.zakaut .btn-group-vertical {
    > .jr-btn {
        &.active {
            background-color: $app-primary-zakaut;
            border-color: $app-primary-zakaut;
        }
    }

    > .jr-flat-btn {
        background-color: transparent;
        border-color: transparent;
        &.active {
            background-color: transparent;
            color: $app-primary-zakaut;
            &:hover,
            &:focus {
                background-color: rgba($app-primary-zakaut, 0.12);
                color: $app-primary-zakaut;
            }
        }
    }
}

/*Classic Dashboard Styles*/
.zakaut .jr-fillchart-btn-close,
.zakaut .jr-onchart .jr-badge-up,
.zakaut .jr-task-list-item:hover .jr-text-hover {
    color: $app-primary-zakaut;
}

.zakaut .jr-entry-title:before {
    background-color: $app-primary-zakaut;
}

.zakaut .jr-card-ticketlist {
    & .jr-task-list-item:hover .jr-task-item-title,
    .jr-link {
        color: $app-primary-zakaut;
    }
}

.zakaut .slick-dots li.slick-active button:before {
    border-color: $app-primary-zakaut;
}

//Nav Styles
.zakaut .nav-pills .nav-link.active,
.zakaut .nav-pills .show > .nav-link {
    color: $white !important;
    background-color: $app-primary-zakaut;
}

// Gradient Color Class
.zakaut .bg-gradient-primary {
    @include gradient-directional($app-primary-zakaut, lighten($app-primary-zakaut, 16%), 0deg);
}

.zakaut .bg-gradient-primary-x {
    @include gradient-x(darken($app-primary-zakaut, 10%), lighten($app-primary-zakaut, 16%), 70%, 100%);
}

//Profile style
.zakaut .jr-profile-banner {
    background-color: $app-primary-zakaut;
    color: $white;

    & .jr-link {
        color: $white;

        &:hover,
        &:focus {
            color: $secondary-zakaut;
        }
    }
}

.zakaut .dropdown-item {
    &.active,
    &:active {
        background-color: $app-primary-zakaut;
        color: $white;
    }

    &[class*="text-"] {
        &.active,
        &:active {
            background-color: transparent;
        }
    }
}

.zakaut .app-logo-content > div {
    width: 100%;
}
@media screen and (max-width: 575px) {
    .zakaut .app-logo-content > div {
        width: 40%;
    }
}

.zakaut .app-sidebar-logo > div {
    display: flex;
    justify-content: center;
    width: 250px;
}

.zakaut .app-sidebar-logo > div > div {
    align-self: center;
    padding-left: 10px;
    white-space: initial;
}
.zakaut .app-sidebar-logo > div > div:nth-child(1) {
    flex: 1;
}
.zakaut .app-sidebar-logo > div > div:nth-child(2) {
    flex: 3;
}

.zakaut .app-top-nav {
    background-color: #615045 !important;
}

.zakaut .navbar-nav li a,
.zakaut .navbar-nav li .nav-link {
    color: #fff;
}

.zakaut .app-header-horizontal .app-main-header {
    z-index: 1200;
}

.zakaut .MuiButton-textPrimary {
    color: #85634b;
}

/*Progressbar Styles*/

.pb-wrapper {
    display: flex;
    align-items: center;
}

.pb-btn-wrapper {
    margin: 8px;
    position: relative;
    .btn-success {
        background-color: map_get($green, base);
        &:hover {
            background-color: map_get($green, darken-2);
        }
    }
}

.fab-progress {
    color: map_get($green, base);
    position: absolute;
    top: -6px;
    left: -6px;
    z-index: 1;
}

.btn-progress {
    color: map_get($green, base);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}

.progress-height {
    height: 6px !important;
}

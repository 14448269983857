/*Right Sidebar Styles*/
.app-sidebar-content.right-sidebar {
    z-index: 1501 !important;
}

.color-theme {
    @include display-flex(flex, column, nowrap);
    max-width: 300px;

    @media screen and (max-width: 499px) {
        max-width: 250px;
    }
}

.color-theme-header {
    background-color: $app-primary;
    color: $white;
    padding: 13px 25px;
    text-transform: uppercase;
    @include display-flex();
    @include align-items(center);
    @include justify-content(space-between);

    & .color-theme-title {
        margin-bottom: 0;
        color: $white;
    }

    @media screen and (max-width: 499px) {
        padding: 16px 15px;
    }
}

.color-theme-body {
    padding: 28px 25px;

    & h3 {
        margin-bottom: 20px;
    }

    @media screen and (max-width: 499px) {
        padding: 25px 15px;
    }
}

.color-option {
    list-style: none;
    padding-left: 0;
    margin: 0 -7px 10px;

    & li {
        position: relative;
        display: inline-block;
        padding: 0 7px;
        margin-bottom: 10px;
    }

    & li span.jr-link {
        height: 45px;
        width: 45px;
        line-height: 45px;
        display: inline-block;
        color: transparent !important;
        position: relative;
        cursor: pointer;

        &:before {
            content: "\f26b";
            font-family: "Material-Design-Iconic-Font";
            font-size: 20px;
            height: 45px;
            width: 45px;
            line-height: 45px;
            position: absolute;
            top: 0;
            left: 0;
            color: $white;
            text-align: center;
            display: none;
            z-index: 2;
        }
    }

    & li span.jr-link.active {
        &:before {
            display: block;
        }
    }
}

.cr-op-dark-sidebar {
    & li span.jr-link {
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            background-color: $body-color;
            width: 8px;
        }
    }
}

//Coustomizer css
.theme-option {
    position: fixed;
    top: 30%;
    right: 0;
    z-index: 1500;
    background-color: map-get($purple, base);
    @include border-left-radius($border-radius);
    @include box-shadow(1px 1px 4px rgba($black, 0.5));
    color: $white;
}

@media screen and (max-width: 499px) {
    .color-theme .jr-btn {
        padding: 10px 15px !important;
    }
}

.side-nav-option .sidenav-dir {
    @include display-flex();
    margin: 0 -8px;

    & > label {
        width: 50%;
        margin: 0;
        padding: 0 8px;
    }
}

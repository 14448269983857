/*Carousel Styles*/

.carousel-caption {
    & h3 {
        font-size: $h2-font-size;
    }
}

.carousel-control-prev {
    @extend %pointer;
}

.carousel-control-next {
    @extend %pointer;
}

.slick-slider {
    margin-bottom: $jr-card-margin;

    & .slick-prev {
        left: -10px;

        &:before {
            color: $black;
        }
    }

    & .slick-next {
        right: -10px;

        &:before {
            color: $black;
        }
    }

    & img {
        width: 100%;
    }
}

.slick-dots {
    & li {
        width: 10px !important;
        height: 10px !important;
    }

    & li button {
        width: 10px !important;
        height: 10px !important;

        &:before {
            width: 10px !important;
            height: 10px !important;
            line-height: 10px !important;
            color: transparent !important;
            border: solid 2px $black;
            @include border-radius($border-radius-circle);
        }
    }

    & li.slick-active button {
        width: 12px !important;
        height: 12px !important;
    }

    & li.slick-active button:before {
        color: transparent !important;
        border-color: $app-primary;
        width: 12px !important;
        height: 12px !important;
        line-height: 12px !important;
    }
}

.jr-slide-item {
    margin-left: 15px;
    margin-right: 15px;

    @media screen and (max-width: 480px) {
        margin-left: 0;
        margin-right: 0;
    }
}

.jr-slider {
    position: relative;

    &-img {
        position: relative;
        padding-bottom: 18px;

        .jr-img-lg {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        .jr-img-up {
            position: absolute;
            left: 18px;
            bottom: -5px;
            z-index: 1;
            @include border-radius($border-radius-lg);
            width: auto;
            max-width: ($size-60 + 4);
        }
    }

    &-content {
        padding: 24px 24px 40px;

        @media screen and (max-width: 575px) {
            padding-bottom: 24px;
        }
    }
}

.jr-slick-slider {
    margin-bottom: 0;

    & .slick-dots {
        bottom: 24px;
        padding: 0 18px;
        text-align: left;
    }

    &-dot-top {
        padding-top: 40px;
        padding-right: 18px;

        & .slick-dots {
            bottom: auto;
            top: 10px;
            padding-left: 0;
        }

        @media screen and (max-width: 575px) {
            padding-left: 18px;
        }
    }
}

.jr-slick-slider-lt-thumb {
    height: 100%;
    overflow: hidden;

    & img {
        height: 100%;

        @media screen and (max-width: 575px) {
            width: 100%;
        }
    }
}

/*Button Group Styles*/

// stylelint-disable selector-no-qualifying-type

// Make the div behave like a button
.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; // match .btn alignment given font-size hack above

    > .jr-btn {
        background-color: $gray-200;
        position: relative;
        flex: 0 1 auto;
        border-color: $border-color;

        // Bring the hover, focused, and "active" buttons to the front to overlay
        // the borders properly
        @include hover {
            z-index: 2;
        }
        &:focus,
        &:active,
        &.active {
            z-index: 2;
        }

        &.active {
            background-color: $app-primary;
            color: $white;
            border-color: $app-primary;
        }
    }

    > .jr-flat-btn {
        background-color: transparent;
        border-color: transparent;
        &.active {
            background-color: transparent;
            color: $app-primary;
            &:hover,
            &:focus {
                background-color: rgba($text-muted, 0.12);
                color: $app-primary;
            }
        }
    }

    // Prevent double borders when buttons are next to each other
    .jr-btn + .jr-btn,
    .jr-btn + .jr-btn-group,
    .btn-group + .jr-btn,
    .btn-group + .btn-group {
        margin-left: -$input-btn-border-width;
    }
}

// Optional: Group multiple button groups together for a toolbar
.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .input-group {
        width: auto;
    }
}

.btn-group > .jr-btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: 0 !important;
}

// Set corners individual because sometimes a single button can be in a .btn-group
// and we need :first-child and :last-child to both match
.btn-group > .jr-btn:first-child {
    margin-left: 0;

    &:not(:last-child):not(.dropdown-toggle) {
        @include border-right-radius(0 !important);
    }
}

// Need .dropdown-toggle since :last-child doesn't apply given a .dropdown-menu
// immediately after it
.btn-group > .jr-btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
    @include border-left-radius(0 !important);
}

// Custom edits for including btn-groups within btn-groups (useful for including
// dropdown buttons within a btn-group)
.btn-group > .btn-group {
    float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
    border-radius: 0 !important;
}

.btn-group > .btn-group:first-child:not(:last-child) {
    > .jr-btn:last-child,
    > .dropdown-toggle {
        @include border-right-radius(0 !important);
    }
}

.btn-group > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
    @include border-left-radius(0 !important);
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn-group-sm > .jr-btn {
    @extend .jr-btn-sm;
}

.btn-group-lg > .jr-btn {
    @extend .jr-btn-lg;
}

//
// Split button dropdowns
//

.jr-btn + .dropdown-toggle-split {
    padding-right: $input-btn-padding-x * 0.75;
    padding-left: $input-btn-padding-x * 0.75;

    &::after {
        margin-left: 0;
    }
}

.jr-btn-sm + .dropdown-toggle-split {
    padding-right: $input-btn-padding-x-sm * 0.75;
    padding-left: $input-btn-padding-x-sm * 0.75;
}

.jr-btn-lg + .dropdown-toggle-split {
    padding-right: $input-btn-padding-x-lg * 0.75;
    padding-left: $input-btn-padding-x-lg * 0.75;
}

// The clickable button for toggling the menu
// Set the same inset shadow as the :active state
.btn-group.show .dropdown-toggle {
    @include box-shadow($btn-active-box-shadow);

    // Show no shadow for `.btn-link` since it has no other button styles.
    &.btn-link {
        @include box-shadow(none);
    }
}

//
// Vertical button groups
//

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .jr-btn,
    .btn-group {
        width: 100%;
    }

    > .jr-btn + .jr-btn,
    > .jr-btn + .btn-group,
    > .btn-group + .jr-btn,
    > .btn-group + .btn-group {
        margin-top: -$input-btn-border-width;
        margin-left: 0;
    }

    > .jr-btn {
        &:not(:first-child):not(:last-child) {
            border-radius: 0 !important;
        }

        &:first-child:not(:last-child) {
            @include border-bottom-radius(0 !important);
        }

        &:last-child:not(:first-child) {
            @include border-top-radius(0 !important);
        }
    }

    > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
        border-radius: 0 !important;
    }

    > .btn-group:first-child:not(:last-child) {
        > .jr-btn:last-child,
        > .dropdown-toggle {
            @include border-bottom-radius(0 !important);
        }
    }

    > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
        @include border-top-radius(0 !important);
    }
}

[data-toggle="buttons"] {
    > .jr-btn,
    > .btn-group > .jr-btn {
        input[type="radio"],
        input[type="checkbox"] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
        }
    }
}

// Make the div behave like a button
.btn-group,
.btn-group-vertical {
    // Over Write To jr btn Margin
    > .jr-btn,
    > .btn {
        margin: 0 !important;
    }
}

/*Blockquotes Styles*/
blockquote {
    padding: 10px 0 10px 20px;
    font-size: $font-size-base !important;

    .blockquote-footer {
        font-size: $font-size-base !important;
    }
}

.blockquote {
    border-left: 5px solid $gray-200;
}

.blockquote-reverse {
    border-left: 0 none;
    border-right: 5px solid $gray-200;
    text-align: right;
    padding-left: 0;
    padding-right: 20px;
}

/*Popover Styles*/
.popover {
    z-index: 1510;
}

.popover-header {
    background-color: transparent;
    border-bottom: 0 none;
    color: $black;
    font-size: $h2-font-size;
    padding: 15px 20px 0;
}

.popover-body,
.popover-inner {
    padding: 15px 20px;

    & h3 {
        font-size: 16px;
    }

    & h4 {
        font-size: 14px;
    }
}

.jr-card .jr-card-body .MuiPaper-root {
    background: transparent;
}

.app-main-header-light-blue {
    background-color: #7688ff !important;
}

.app-logo-content .logo-title {
    text-align: center;
    color: #fff;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
    font-family: sans;
}
.app-logo-content {
    position: relative;
}
.app-logo-content img {
    position: absolute;
    width: calc(100% - 70px);
    top: 50%;
    transform: translateY(-50%);
}

.app-login-content input {
    min-width: 332px;
}

.app-sidebar-logo img {
    float: left;
    width: 55px;
}

.app-sidebar-logo .logo-title {
    float: left;
    text-align: center;
    color: #fff;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
    padding-left: 15px;
    font-family: sans;
}
.app-sidebar-logo .logo-title:after {
    clear: both;
    display: block;
}

.ibv_page img {
    max-width: 600px;
}

.avatar_dropzone_active {
    border: 1px solid #fff;
    border-radius: 100px;
    background: #fff !important;
}
.avatar_dropzone_active img {
    display: none;
}

.one-line-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.import-dropzone {
    height: 190px;
    width: 390px;
    border: 2px dashed #ccc;
}
.import-dropzone .react-fine-uploader-file-input-container {
    height: 190px;
    width: 390px;
}
.import-dropzone .react-fine-uploader-file-input-container div {
    text-align: center;
    margin-top: 50px;
}
.import-dropzone-active {
    border: 2px dashed darkgreen;
}

.import-dropzone-example {
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
}

.theme-healthylab .app-logo-content .logo-title {
    text-transform: initial;
    font-family: sans-serif;
}

.theme-healthylab .app-sidebar-logo .logo-title {
    font-size: 28px;
    text-transform: initial;
    padding-left: 6px;
    padding-top: 6px;
    font-family: sans-serif;
}

/*
.is_select_input .MuiInputLabel-outlined {
  margin-top:-8px;
}
.is_select_input .MuiFormLabel-filled {
  margin-top:0px;
}
*/
.is_select_input .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1) !important;
}
.is_select_input .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75) !important;
}

.is-location-label .MuiInputLabel-outlined {
    transform: translate(14px, -6px) scale(0.75) !important;
}

.is_select_input {
    margin-top: 6px !important;
    margin-bottom: 4px !important;
}

.input-field-file input {
    display: none;
}

.chat-message-date {
    font-size: 10px;
}

.chat-message .chat-message-options {
    display: none;
}
.chat-message:hover .chat-message-options {
    display: block;
}
.chat-message-text {
    float: left;
}
.chat-message-options {
    float: left;
}
.chat-message-options:after {
    clear: both;
}
.chat-message-quote {
    border-left: 2px #999 solid;
    padding: 5px 10px 0px 10px;
}

.chat-message-image-button input {
    display: none;
}
.chat-message-video-preview {
}
.chat-message-video-preview #video-rec-recording {
    display: none;
    position: absolute;
}
.chat-message-video-preview #video-rec-preview {
    display: none;
    position: absolute;
    bottom: 100px;
    right: 100px;
    z-index: 9009999;
    width: 320px;
    height: 240px;
}

.chat-message-record-active {
    animation: blinker 1s step-start infinite;
    color: red !important;
}
.primary-block {
    border: 1px red solid;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.chat-message-avatar {
    background: #fff;
}

.chat-video {
    height: calc(100vh - 470px);
}
.chat-video iframe {
    width: 100%;
    height: 100%;
}

.jr-profile-content .app-module {
    margin-top: 0px;
}
.jr-profile-content .app-wrapper {
    box-shadow:
        0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 0px;
    height: 0px;
}

.jr-profile-content .jr-profile-banner:before {
    background-color: inherit;
}

.jr-profile-banner .jr-navbar-nav li a.active {
    text-decoration: underline !important;
}

.file-manager .file-avatar {
    margin-left: 10px;
}
.file-manager .file-icon {
    width: 40px;
    height: 40px;

    margin-right: 10px;
}

.file-manager-dropzone-active {
    border: 1px #000 solid;
}

/*тарифные планы*/
.chat-item .bubble {
    max-width: 730px;
}
.hl-plans {
    white-space: normal !important;
}
.hl-plans .plan {
    width: 200px;
    height: auto;
    border-radius: 20px;
    background-color: #ffffff;
    border: solid 1px #e4e6ed;
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
}
.hl-plans .plan .title {
    width: 200px;
    height: 24px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    text-align: center;
    color: #222f2d;
    margin-top: 25px;
    text-align: center;
}
.hl-plans .plan .price_block {
    margin: 25px 15px 0px 15px;
    width: 200px;
    height: 52px;
}

.hl-plans .plan .price_block .num {
    height: 52px;
    font-family: Roboto;
    font-size: 30px;
    line-height: 1;
    text-align: center;
    color: #222f2d;
    float: left;
}

.hl-plans .plan .price_block .text {
    float: left;
    margin-left: 7px;
}

.hl-plans .plan .price_block .text .rub {
    width: 110px;
    height: 14px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 0.7;
    text-align: left;
    color: #222f2d;
    margin-top: 5px;
}

.hl-plans .plan .price_block .text .period {
    height: 14px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 0.88;
    text-align: left;
    color: #222f2d;
    margin-top: 10px;
}

.hl-plans .plan .description_block {
    width: 200px;
    margin: 0 auto;
    height: auto;
    margin-bottom: 30px;
}

.hl-plans .plan .description_block .icon {
    float: left;
    width: 40px;
    height: 40px;
    padding-left: 10px;
    display: none;
}

.hl-plans .plan .description_block .text {
    width: 171px;
    padding: 0px 20px;
    height: auto;
    font-family: Roboto;
    font-size: 14px;
    line-height: 1.57;
    color: #9baabc;
}

.hl-plans .plan .button {
    width: 200px;
    height: auto;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    padding: 15px 0px;
    color: #2cc1a7;
    border-top: solid 1px #e4e6ed;
    cursor: pointer;
    text-decoration: none;
    display: block;
}

.radio-group-title {
    margin: 10px 10px 10px 0px;
}

.card-title {
    font-weight: bold;
}

a {
    color: #2196f3;
}

@media screen and (max-width: 400px) {
    .app-footer {
        display: none;
    }
}

.popover {
    background: transparent;
    border: 0;
}
.popover-inner {
    padding: 0px;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.data-table-avatar {
    display: flex;
    justify-content: center;
}
.data-table-avatar > div:nth-child(1) {
    flex: 90px;
    align-self: center;
    padding: 6px 5px;
    white-space: initial;
}
.data-table-avatar > div:nth-child(2) {
    flex: 40px;
    margin-right: 10px;
    align-self: center;
}

.avatar-admin {
    font-size: 20px;
}

/* filter for DataTable */
.filter-field select:focus::placeholder {
    color: #444;
}
.filter-field__full_width .is_select_input{
    width: 100%!important;
}

.app-main-header .filter-field select:placeholder {
    color: #333;
}

.app-main-header .search-bar input,
.filter-field .is_select_input {
    width: 200px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
    height: 35px;
}

.app-main-header .filter-field .MuiInputBase-root {
    /*height:35px;*/
}
.MuiTextField-root.disabled {
    opacity: 0.5;
}

.app-main-header .filter-field .MuiInputLabel-formControl,
.app-main-header .filter-field .MuiOutlinedInput-root,
.app-main-header .filter-field .MuiSelect-outlined,
.app-main-header .search-bar input::placeholder {
    font-size: 12px;
    color: #555;
}
.app-main-header .search-bar input + .search-icon i {
    color: #555;
}

.app-main-header .filter-field .PrivateNotchedOutline-legendLabelled-6 {
    height: 8px;
}

.app-main-header .filter-field .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1) !important;
}
.app-main-header .filter-field .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -4px) scale(0.75) !important;
    color: #222;
}

.app-main-header .filter-field .MuiOutlinedInput-notchedOutline {
    border: none;
}

.app-main-header .search-bar input:focus::placeholder {
    color: #444;
}

.app-main-header .filter-field .MuiAutocomplete-endAdornment {
    top: calc(50% - 17px);
}

/* analytics header */

.header-right-tabs {
    border-radius: 0.375rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
}
.header-right-tabs .title {
    padding: 20px 20px;
    font-weight: bold;
}

/* Google Maps */
.pac-container {
    z-index: 9999;
}

/* EditForm dialog group records */
fieldset.edit-form-dialog-fieldset {
    min-width: inherit !important;
    padding: inherit !important;
    margin: 15px 0px 0px 0px !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
}

fieldset.edit-form-dialog-fieldset > legend {
    font-weight: bold;
    width: inherit !important;
    font-size: inherit !important;
    margin: 0px !important;
    padding: 0px 5px !important;
}

.width3000 {
    width: 3000px;
}

/* CK edtior */
.ck-editor__editable {
    min-height: 400px !important;
}

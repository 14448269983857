/*Navbar Styles*/
.app-main-menu {
    & .navbar-nav {
        @include display-flex();
    }
}

.navbar-nav {
    position: relative;

    & li {
        position: relative;

        &:hover,
        &:focus {
            & > ul.sub-menu {
                opacity: 1;
                visibility: visible;
                top: 100%;
            }

            & > .nav-link,
            & > a {
                color: $secondary;
            }
        }

        & a,
        & .nav-link {
            display: block;
            color: $sidebar-text-color;
            padding: 10px 0;
            font-size: 14px;
        }

        & a:hover,
        & a:focus,
        & .nav-link:hover,
        & .nav-link:focus {
            text-decoration: none;
            color: $app-primary;
        }

        &.mega-menu {
            position: static;
        }

        &.mega-menu ul.sub-menu {
            @include display-flex();
            width: 84%;
            margin: 0 auto;
            left: 0;
            right: 0;

            & li {
                width: 25%;
                padding: 0 15px;
            }
        }
    }

    & > li {
        &:last-child {
            ul.sub-menu {
                left: auto;
                right: 0;
            }

            ul.sub-menu ul.sub-menu {
                left: auto;
                right: 100%;
                top: 50px;
            }

            ul.sub-menu li:hover > ul.sub-menu,
            ul.sub-menu li:focus > ul.sub-menu {
                top: 0;
            }
        }
    }

    & li.nav-item {
        padding: 0 10px;

        @media screen and (max-width: 1099px) {
            padding: 0 5px;
        }

        & > .nav-link,
        & > a {
            padding: 24px 0 25px;
            color: $white;
        }

        &.active > .nav-link,
        &.active > a {
            color: $secondary;
        }
    }

    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus {
        color: $secondary;
    }

    ul.sub-menu {
        list-style: none;
        border: solid 1px $gray-400;
        position: absolute;
        left: 0;
        top: 100px;
        opacity: 0;
        padding: 10px 0;
        background-color: $white;
        visibility: hidden;
        margin-top: -1px;
        @include border-radius(4px);
        @include transition(all 0.5s ease-in-out);

        & li {
            & a,
            & .nav-link {
                padding: 5px 15px;
                width: 200px;
                font-size: 14px;

                &.active {
                    color: $app-primary;
                }
            }

            & > ul.sub-menu {
                left: 100%;
                right: auto;
                top: 50px;
            }

            &:hover > ul.sub-menu,
            &:focus > ul.sub-menu {
                top: 0;
            }

            & a i,
            & .nav-link i {
                margin-right: 10px;
            }

            &.active > a,
            &.active > .nav-link {
                color: $app-primary;
            }
        }
    }

    & .nav-arrow {
        & > a,
        & > .nav-link {
            position: relative;

            &:before {
                color: $sidebar-text-color;
                content: "\f2fb";
                display: inline-block;
                float: right;
                font: {
                    family: "Material-Design-Iconic-Font";
                    size: 15px;
                }
                margin-left: 10px;
                position: relative;
                @include transition(all 300ms ease-in-out);
                font-weight: normal;
            }
        }

        & a:hover,
        & a:focus,
        & .nav-link:hover,
        & .nav-link:focus {
            &:before {
                color: $app-primary;
            }
        }

        &:hover,
        &:focus,
        &.active {
            & > a:before,
            & > .nav-link:before {
                color: $app-primary;
            }
        }
    }

    ul.sub-menu-half {
        @include display-flex();
        width: 300px;

        & li {
            width: 50%;

            & a {
                width: 100%;

                @media screen and (max-width: 1099px) {
                    font-size: 13px;
                }
            }
        }

        @media screen and (max-width: 1099px) {
            width: 262px;
        }
    }
}

.navbar-nav-mega {
    position: static !important;
}

.app-top-nav {
    background-color: #252525 !important;

    & .navbar-nav {
        & li.nav-item {
            padding: 0 30px;
        }

        & li.nav-item > a,
        & li.nav-item > .nav-link {
            color: $white;
            padding: 9px 0 10px;
        }

        & li.nav-item:hover > a,
        & li.nav-item:focus > a,
        & li.nav-item > a:hover,
        & li.nav-item > a:focus,
        & li.nav-item.active > a,
        & li.nav-item:hover > .nav-link,
        & li.nav-item:focus > .nav-link,
        & li.nav-item > .nav-link:hover,
        & li.nav-item > .nav-link:focus,
        & li.nav-item.active > .nav-link {
            color: $secondary;
        }

        & ul.sub-menu {
            margin-top: 0;
        }
    }
}

//Profile App Style
.jr-profile-banner {
    padding: 30px;
    padding-bottom: 82px;
    margin: -30px;
    margin-bottom: -55px;
    background-color: $app-primary;
    color: $white;
    position: relative;

    @media screen and (max-width: 575px) {
        padding: 20px 15px;
        padding-bottom: 77px;
        margin: -20px;
        margin-bottom: -58px;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.3);
    }

    & > div {
        position: relative;
        z-index: 2;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: $white;
    }

    & .jr-link {
        color: $white;
        cursor: pointer;

        &:hover,
        &:focus {
            color: $secondary;
        }
    }
}

.jr-profile-banner-top {
    @include display-flex(flex, row, wrap);
    @include align-items(center);
    margin-bottom: 40px;

    @media (max-width: 575px) {
        @include display-flex(flex, column, nowrap);
        margin-bottom: 25px;
    }
}

.jr-profile-banner-top-left {
    @include display-flex(flex, row, wrap);
    @include align-items(center);

    @media (max-width: 575px) {
        @include display-flex(flex, column, nowrap);
        margin-bottom: 25px;
    }
}

.jr-profile-banner-avatar {
    margin-right: 1.5rem;

    @media (max-width: 575px) {
        margin-bottom: 8px;
        margin-right: 0;
    }
}

.jr-profile-banner-top-right {
    text-align: center;
    margin-left: auto;

    @media (max-width: 575px) {
        margin-left: 0;
    }
}

.jr-profile-banner-bottom {
    @include display-flex(flex, row, wrap);
    @include align-items(center);

    @media (max-width: 575px) {
        @include display-flex(flex, column, nowrap);

        .jr-tab-list {
            margin-bottom: 10px;
        }
    }
}

.jr-profile-content {
    position: relative;
    z-index: 2;
}

.jr-profile-setting {
    margin-left: auto;

    @media (max-width: 575px) {
        margin-left: 0;
    }
}

.jr-profile-content-right {
    @media (max-width: 575px) {
        @include display-flex(flex, row, wrap);
        @include align-items(center);

        .text-truncate {
            width: auto;
            margin-left: auto;
        }
    }
}

.jr-pro-contact-list {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.jr-card-profile {
    position: relative;

    &-sm {
        position: relative;
    }

    & .card-header {
        background-color: transparent;
        border-bottom: 0 none;
        padding: 18px $jr-card-padding;

        @media (max-width: 575px) {
            border-bottom: solid 1px $border-color;
        }
    }

    & .card-title {
        font-size: 18px;
        text-transform: capitalize;
    }

    & .jr-tabs-up {
        margin-top: -50px;

        @media (max-width: 575px) {
            margin-top: 0;
            margin-left: 30px;
            margin-right: 30px;
        }
    }

    & .jr-tabs-content {
        padding: 10px $jr-card-padding 20px;
    }
}
